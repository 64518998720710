@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');

.generator-wrapper {
    font-family: 'Ubuntu', sans-serif;
    width: 370px;
    border: 1px solid black;
    border-radius: 20px;
    background-color: rgb(9, 9, 59);
    color: #f5f5f5;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.generator-wrapper h1 {
    font-size: 1.7rem;
}

.box {
    background-color: rgb(35, 41, 77);
    width: 90%;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0 10px 0;
}

.generator-slider input{
    width: 90%;
    background: transparent;
}

.generator-slider input[type=range]::-moz-range-track {
    background-color: #9c9c9c;
  }
  

.generator-slider input[type=range]::-moz-range-progress {
    background-color: #517aff;
    height: 4px;
  }

.generator-slider input[type=range]::-moz-range-thumb {
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 100%;
}

.generator-slider span{
    margin: 0 5px 0 5px;
}

.generator-result {
    position: relative;
    justify-content: center;
    align-items: center;
    height: 70px;
}

.generator-result span {
    position: absolute;
    right: 10px;
    bottom: 5px;
    color: #888;
    font-size: small;
}

.generator-result:hover{
    cursor: url('../../media/icons/copy32.png'), pointer;
    background-color: rgb(92, 99, 143);

}

.generator-options {
    justify-content: space-between;
    color: #FFFF;
}

.generator-lbl{
    font-size: 0.65rem;
    text-transform: uppercase;
    align-self: start;
    margin-left: 25px;
    color: #c2c0c0;
}

.generator-lbl strong{
    color: #FFFF;
    font-size: 0.7rem;
}

.btn-generator{
    text-transform: uppercase;
    margin: 20px 0 20px 0;
    border: none;
    background: linear-gradient(to right,#7431f0 , #8927a7);
    color: #f5f5f5;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    width: 90%;
    height: 50px;
    padding: 7px;
}

.btn-generator:hover {
    cursor: pointer;
    transform: scale(1.05);
}

    @media screen and (max-width: 550px)  {
        .generator-wrapper {
            transform: scale(0.8);
        }
    }
