@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.generator-wrapper {
    font-family: 'Ubuntu', sans-serif;
    width: 370px;
    border: 1px solid black;
    border-radius: 20px;
    background-color: rgb(9, 9, 59);
    color: #f5f5f5;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.generator-wrapper h1 {
    font-size: 1.7rem;
}

.box {
    background-color: rgb(35, 41, 77);
    width: 90%;
    height: 60px;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0 10px 0;
}

.generator-slider input{
    width: 90%;
    background: transparent;
}

.generator-slider input[type=range]::-moz-range-track {
    background-color: #9c9c9c;
  }
  

.generator-slider input[type=range]::-moz-range-progress {
    background-color: #517aff;
    height: 4px;
  }

.generator-slider input[type=range]::-moz-range-thumb {
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 100%;
}

.generator-slider span{
    margin: 0 5px 0 5px;
}

.generator-result {
    position: relative;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 70px;
}

.generator-result span {
    position: absolute;
    right: 10px;
    bottom: 5px;
    color: #888;
    font-size: small;
}

.generator-result:hover{
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAARRQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA////SKzSWAAAAFt0Uk5TABqo2dXh3Dl1oiMbHBhV7MExmG1Duni/LrcNcSsBFyIgpgkDcrMmAmWsqt5qqS09EA+fQdJzbHDNtRWXhZaVlJPMQESRknYREj/AuK2uu2QHHiGbbim9QqXXoQKpMGYAAAABYktHRFt0vJU0AAAACXBIWXMAAAJHAAACRwHTGzD1AAAA40lEQVQ4y2NggABGJmYWBGBlY2dAAxycXNw8MMDLxy8giKZASBiZJyIqJi6BV4GklLSoDJwrKyevoIimQElZRVUNylPXENXU0tZBU8CgLKyrAOHpiesbGBqhmGBsYipsZm5haQVRbi2L7gZGG1s7O3sHRycwz9kFw5FQ4OoMUeA6qmBUATCtCokgFLhx8rh7IAN3D3dPLxmEAjk7b00fBNBU9db09fNXRyhgCAiUD0KAQI5gBXk5WYhV4DSJDoSFEGwRIXVMBSHWCHZoWDgi30IAN1cEkq/UzVgiWdAAcxQvVBYA6DE3bBmo4FkAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTctMDQtMjBUMTg6MTY6NTArMDI6MDDLgafZAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE3LTA0LTIwVDE4OjE2OjUwKzAyOjAwutwfZQAAAEZ0RVh0c29mdHdhcmUASW1hZ2VNYWdpY2sgNi43LjgtOSAyMDE2LTA2LTE2IFExNiBodHRwOi8vd3d3LmltYWdlbWFnaWNrLm9yZ+a/NLYAAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAYdEVYdFRodW1iOjpJbWFnZTo6aGVpZ2h0ADUxMsDQUFEAAAAXdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgANTEyHHwD3AAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNDkyNzA1MDEwl+msLAAAABN0RVh0VGh1bWI6OlNpemUANy4yNUtCQlqvFtkAAABDdEVYdFRodW1iOjpVUkkAZmlsZTovLy4vdXBsb2Fkcy9jYXJsb3NwcmV2aS9memJtYlozLzEyMzkvY29weV84MzkxOC5wbmeX+82sAAAAAElFTkSuQmCC), pointer;
    background-color: rgb(92, 99, 143);

}

.generator-options {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color: #FFFF;
}

.generator-lbl{
    font-size: 0.65rem;
    text-transform: uppercase;
    -webkit-align-self: start;
            align-self: start;
    margin-left: 25px;
    color: #c2c0c0;
}

.generator-lbl strong{
    color: #FFFF;
    font-size: 0.7rem;
}

.btn-generator{
    text-transform: uppercase;
    margin: 20px 0 20px 0;
    border: none;
    background: linear-gradient(to right,#7431f0 , #8927a7);
    color: #f5f5f5;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    width: 90%;
    height: 50px;
    padding: 7px;
}

.btn-generator:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

    @media screen and (max-width: 550px)  {
        .generator-wrapper {
            -webkit-transform: scale(0.8);
                    transform: scale(0.8);
        }
    }

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9c9c9c;
    transition: all .6s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -2px;
    bottom: -3px;
    background-color: rgb(247, 247, 247);
    transition: .5s;
}

input:checked+.slider {
    background-color: #517aff;
    transition: .6s;

}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
            transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.App {
  width: 100vw;
  min-height: 100vh;
  padding-top: 30px;
  padding-bottom: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgb(49, 158, 247);
  box-sizing: border-box;
}

@media screen and (max-width: 550px)  {
  .App {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

