.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9c9c9c;
    transition: all .6s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -2px;
    bottom: -3px;
    background-color: rgb(247, 247, 247);
    transition: .5s;
}

input:checked+.slider {
    background-color: #517aff;
    transition: .6s;

}

input:checked+.slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}